<template>
  <div>
    <edit-block
      :state="state"
      :status="status"
      :showStatus="true"
      :AuditStatus="dataSource.AuditStatus"
      :title="title"
    >
      <!-- 未填写按钮 -->
      <div slot="opt">
        <span @click="updateState(1)" class="text-primary cursor-pointer"
          >添加</span
        >
      </div>
      <!-- 填写中 -->
      <ExpertEdit
        @cancel="cancel"
        @save="finish"
        :model="model"
        slot="editing"
        :options="options"
      />
      <!-- 已填写 -->
      <ExpertDisplay :options="options" :model="model" slot="display" />
      <!-- 已填写按钮 -->
      <div slot="display-opt" class="flex items-center">
        <div class="display-opt">
          <a-space>
            <a-popconfirm
              title="是否删除该专家?"
              ok-text="删除"
              cancel-text="取消"
              @confirm="del"
            >
              <a-space class="cursor-pointer text-primary" v-if="!isManager"
                ><img src="@/assets/icons/del.svg" alt="" /><span
                  >删除</span
                ></a-space
              >
            </a-popconfirm>

            <a-space
              class="cursor-pointer text-primary"
              @click="submit"
              v-if="!isManager"
            >
              <img src="@/assets/icons/commit.svg" alt="" />
              <span>提交</span>
            </a-space>
            <a-space
              @click="updateState(1)"
              class="cursor-pointer text-primary"
            >
              <img src="@/assets/icons/modify.svg" alt="" />
              <span>修改</span>
            </a-space>
          </a-space>
        </div>
        <a-select
          v-if="!isManager"
          v-model="visible"
          @change="changeVisible"
          style="width: 150px; margin-left: 10px"
        >
          <a-select-option :value="1"> 公开 </a-select-option>
          <a-select-option :value="0"> 不公开 </a-select-option>
        </a-select>
      </div>
    </edit-block>
    <div class="text-right" v-if="isManager && dataSource.AuditStatus === 1">
      <a-button type="primary mt-20" @click="modal = true">审核</a-button>
    </div>
    <a-modal
      v-model="modal"
      title="审核"
      okText="审核"
      cancelText="取消"
      @ok="evaluteProExpert"
    >
      <a-form-model :model="evaluate">
        
          <a-form-model-item>
            <a-select v-model="evaluate.AuditStatus">
              <a-select-option :value="2">审核通过</a-select-option>
              <a-select-option :value="3">审核失败</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="evaluate.AuditStatus === 3">
            <gf-textarea
              :required="true"
              prop="Feedback"
              :max="300"
              :rows="5"
              v-model="evaluate.Feedback"
            ></gf-textarea>
          </a-form-model-item>
       
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import ExpertEdit from "@/components/user/expert/editing.vue";
import ExpertDisplay from "@/components/user/expert/display.vue";

import { saveExpert, deleteExpert, evaluateExpert, createExpert } from "@/api";
import {
  evaluteProExpert,
  saveProExpert,
  setExpertVisible,
} from "@/api/expert.js";
export default {
  components: { ExpertDisplay, ExpertEdit },
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    status: Boolean,
  },
  data() {
    return {
      model: {},
      rules: {},
      state: 0,
      title: "",
      evaluate: {
        AuditStatus: 2,
        Feedback: "",
      },
      modal: false,
      visible: 0,
    };
  },
  computed: {
    role() {
      return this.$store.state.role;
    },
    isManager() {
      return this.role === 6 || this.role === 7;
    },
  },
  inject: ["options"],
  methods: {
    changeVisible(e) {
      let temp = {
        visible: !!e,
      };
      setExpertVisible(this.id, temp)
        .then(() => {
          this.$message.success("设置可见性成功");
        })
        .catch(() => {
          this.$message.error("设置可见性失败");
        });
    },
    transform({
      Name,
      Gender,
      Tags,
      Desc,
      Photo,
      Industries,
      Identity,
      Fields,
      Honors,
      Services,
      Position,
      Title,
      Political,
      Education,
      Email,
      Department,
      Phone,
      BankAccount,
      BankBranch,
      BankName,
    }) {
      const temp = {
        Name,
        Gender,
        Desc,
        Identity,
        Position,
        Title,
        Phone,
        Email,
        Department,
        BankAccount,
        BankBranch,
        BankName,
      };

      temp.Industries = Industries || [];
      temp.Honors = Honors || [];
      temp.Fields = Fields || [];
      temp.Services = Services || [];
      temp.Tags = Tags || [];
      temp.Political = Political || null;
      temp.Education = Education || null;
      temp.Gender = Gender;
      temp.Photo = Photo || null;
      this.updateState(this.getState(temp), temp);
      this.validate(temp);
      return temp;
    },
    getState({
      Name,
      Gender,
      Tags,
      Desc,
      Photo,
      Industries,
      Identity,
      Fields,
      Honors,
      Services,
      Position,
      Title,
      Political,
      Education,
      Email,
      Department,
      Phone,
      BankAccount,
      BankBranch,
      BankName,
    }) {
      if (
        Position ||
        (Photo && Photo.ID) ||
        Name ||
        Gender ||
        Tags.length ||
        Desc ||
        Industries.length ||
        Identity ||
        Fields.length ||
        Honors.length ||
        Services.length ||
        Title ||
        Political ||
        Education ||
        Email ||
        Department ||
        Phone ||
        BankAccount ||
        BankBranch ||
        BankName
      ) {
        return 2;
      }
      return 0;
    },
    restore({
      Name,
      Gender,
      Tags,
      KeyWords,
      Desc,
      IndustryIDs,
      Industry,
      Identity,
      FieldIDs,
      Field,
      Honors,
      ServiceIDs,
      Service,
      Title,
      Political,
      Education,
      Position,
      Email,
      Department,
      Phone,
      BankAccount,
      BankBranch,
      BankName,
      Photo,
    }) {
      const temp = {
        Photo,
        Name,
        Position,
        Gender,
        Tags,
        KeyWords,
        Desc,
        IndustryIDs,
        Industry,
        Identity,
        FieldIDs,
        Field,
        Honors,
        ServiceIDs,
        Service,
        Title,
        Political,
        Education,
        Email,
        Department,
        Phone,
        BankAccount,
        BankBranch,
        BankName,
      };
      if (temp.Education) {
        temp.Education = {
          ID: temp.Education,
          Name: this.options.education[temp.Education - 1].Name,
        };
      }
      if (temp.Political) {
        temp.Political = {
          ID: temp.Political,
          Name: this.options.political[temp.Political - 1].Name,
        };
      }

      return temp;
    },
    validate({
      Name,
      Photo,
      Gender,
      Tags,
      KeyWords,
      Position,
      Desc,
      Industries,
      Identity,
      Fields,
      Services,
      Title,
      Political,
      Education,
      Email,
      Phone,
      BankAccount,
      BankBranch,
      BankName,
    }) {
      let status = false;
      if (
        this.id &&
        (!Position ||
          !Photo.ID ||
          !Name ||
          !Gender ||
          !Tags ||
          !KeyWords ||
          !Desc ||
          !Industries.length ||
          !Identity ||
          !Fields.length ||
          !Services.length ||
          !Title ||
          !Political ||
          !Education ||
          !Email ||
          !Phone ||
          !BankAccount ||
          !BankBranch ||
          !BankName)
      ) {
        status = true;
      }
      this.$emit("update:status", status);
    },
    cancel(data) {
      this.updateState(this.getState(this.model), data);
    },
    finish(data) {
      const temp = this.restore(data);
      temp.ID = this.id;
      temp.Industries = [];
      temp.IndustryIDs.forEach((item) => {
        if (item > 0) {
          temp.Industries.push({
            ID: item,
            Name: this.options.industry[item - 1].Name,
          });
        }
      });
      if (temp.IndustryIDs.includes(-1)) {
        temp.Industry.forEach((item) => {
          temp.Industries.push({
            ID: 0,
            Name: item,
          });
        });
      }

      temp.Fields = [];
      temp.FieldIDs.forEach((item) => {
        if (item > 0) {
          temp.Fields.push({
            ID: item,
            Name: this.options.expertField[item - 1].Name,
          });
        }
      });
      if (temp.FieldIDs.includes(-1)) {
        temp.Field.forEach((item) => {
          temp.Fields.push({
            ID: 0,
            Name: item,
          });
        });
      }

      temp.Services = [];
      temp.ServiceIDs.forEach((item) => {
        if (item > 0) {
          temp.Services.push({
            ID: item,
            Name: this.options.service[item - 1].Name,
          });
        }
      });
      if (temp.ServiceIDs.includes(-1)) {
        temp.Service.forEach((item) => {
          temp.Services.push({
            ID: 0,
            Name: item,
          });
        });
      }

      if (temp.ID) {
        if (!this.isManager) {
          saveExpert(temp).then(() => {
            this.$message.success("保存专家成功");
            this.model = this.transform(data);
            this.$emit("update");
          });
        } else {
          saveProExpert(temp.ID, temp).then(() => {
            this.$message.success("保存专家成功");
            this.model = this.transform(data);
            this.$emit("update");
          });
        }
      } else {
        createExpert(temp).then(() => {
          this.$message.success("添加新专家");
          this.model = this.transform(data);
          this.$emit("update");
        });
      }
    },
    updateState(state, temp) {
      switch (state) {
        case 0:
          this.title = "新增专家";
          break;
        case 1:
          if (this.state === 0) {
            this.title = "添加专家";
          } else if (this.state === 2) {
            this.title = "修改专家";
          }
          break;
        case 2:
          this.title = `${temp.Name} | ${temp.Position}`;
          break;
      }
      this.state = state;
    },
    submit() {
      evaluateExpert(this.dataSource.ID).then(() => {
        this.$message.success("提交审核成功");
        this.$emit("update");
      });
    },
    evaluteProExpert() {
      evaluteProExpert(this.id, this.evaluate).then(() => {
        this.$message.success("审核成功");
        this.$emit("update");
        this.modal = false;
      });
    },
    del() {
      deleteExpert(this.dataSource.ID).then(() => {
        this.$message.success("删除成功");
        this.$emit("update");
      });
    },
  },
  watch: {
    dataSource: {
      handler(val) {
        const temp = val && val.Detail ? val.Detail : {};
        this.id = val.ID;
        this.AuditStatus = val.AuditStatus;
        this.model = this.transform(temp);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>