<template>
  <div>
    <a-space class="w-full" :size="20" direction="vertical">
      <div class="p-20 bg-white" v-for="(data, index) in origin" :key="index">
        <Expert
          :status.sync="status[index]"
          :dataSource="data"
          @update="update"
        />
      </div>
    </a-space>
  </div>
</template>

<script>
import Expert from "./expert.vue";
import { getExperts } from "@/api";
import { getProExpertDetail } from "@/api/expert.js";
export default {
  components: {
    Expert,
  },
  data() {
    return {
      origin: [],
      status: {},
    };
  },
  created() {
    const id = this.$route.params.id;
    this.id = id;
    this.update();
  },
  methods: {
    update() {
      if (this.id) {
        this.getProExpertDetail(this.id);
      } else {
        this.getExperts();
      }
    },
    getExperts() {
      getExperts().then(({ data }) => {
        const temp = data.rows || [];
        this.origin = temp;
        if (this.origin.length < 5) {
          this.origin.push({});
        }
      });
    },
    getProExpertDetail(id) {
      getProExpertDetail(id).then(({ data }) => {
        this.origin = [data];
      });
    },
  },
};
</script>